.wrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #F5F6FA;
}


.toolbar_wrapper {
    height: 100%;
    position: relative;
    background: #fff;
    box-sizing: border-box;
    width: 250px !important;
    align-items: center;
    gap: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.toolbar_sticky_header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
    padding-bottom: 15px;
    width: 100%;
}

.toolbar_scrollable_content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    min-height: 0;

    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;

        &:hover {
            background: #555;
        }
    }
}

.bot_header {
    box-sizing: border-box;
    height: 100px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 28px 30px 25px 35px;
}

.bot_name_container {
    position: relative;
    display: flex;
    align-items: center;
}

.editIcon {
    opacity: 0;
    transition: opacity 0.3s ease-in-out !important;
}

.bot_name_container:hover .editIcon {
    opacity: 1;
}

.flow_wrapper {
    height: calc(100% - 100px) !important;
    width: 100% !important;
}

.bt_popover_wrapper {
    max-height: 80%;
    min-height: 440px;
    padding: 0px 30px 35px;
    border-radius: 10px;
    background-color: var(--default-background);
    color: #666666;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}


.bt_popover_wrapper_for_settings {
    @extend .bt_popover_wrapper;
    width: 850px;
    padding: 30px 35px;
    color: #666666;
}


.scrollContainer {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px;
}

.scrollContainer::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 10px;
    background-color: #e5e5e5;
}

.scrollContainer::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 10px;
}



.custom_controls {
    margin: 0px 0px 35px 30px !important;
    display: flex;
    align-items: center;
    padding: 9px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 10px 15px 25px !important;
    border-radius: 5px;
    background: rgb(255, 255, 255);
}

.custom_controls button {
    width: 46px;
    height: 28px;
    border: none;
    background: rgb(255, 255, 255);
    display: flex;
    padding: 0px;
}

.custom_controls button:not(:last-child) {
    border-right: 1px solid rgb(232, 234, 242);
}

.custom_controls button:hover {
    background-color: transparent;
}

.custom_controls svg {
    width: auto !important;
    max-width: 23px !important;
    max-height: 23px !important;
    margin: auto;
}

.toolbar_node_button_container {
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 6px;
    color: rgb(255, 255, 255);
    transition: all 0.3s ease 0s;
    height: 90px !important;
    width: 100%;
}

.toolbar_node_button_title {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
}

.toolbar_node_button_secondary_title {
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 150px;
}

.main_node_wrapper {
    min-width: 450px !important;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 30px 60px 65px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: move;
    transition: box-shadow 0.3s ease 0s;
    background-color: white;
}

.secondary_node_wrapper {
    min-width: 180px !important;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 30px 60px 65px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    cursor: move;
    transition: box-shadow 0.3s ease 0s;
    background-color: white;
}

.main_node_wrapper:hover,
.secondary_node_wrapper:hover {
    box-shadow: rgb(232, 234, 242) 0px 0px 5px 1px;
}

.main_node_header {
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
    border-radius: 5px 5px 0px 0px;
    color: rgb(255, 255, 255);
}

.secondary_node_header {
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    color: #666666;
}

.mui_number_field {

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@keyframes bot_loader {

    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.bot_loader>div:nth-child(1) {
    -webkit-animation: bot_loader 1.2s -0.42s infinite ease-in-out;
    animation: bot_loader 1.2s -0.42s infinite ease-in-out;
}

.bot_loader>div:nth-child(2) {
    -webkit-animation: bot_loader 1.2s -0.28s infinite ease-in-out;
    animation: bot_loader 1.2s -0.28s infinite ease-in-out;
}

.bot_loader>div:nth-child(3) {
    -webkit-animation: bot_loader 1.2s -0.14s infinite ease-in-out;
    animation: bot_loader 1.2s -0.14s infinite ease-in-out;
}

.bot_loader>div:nth-child(4) {
    -webkit-animation: bot_loader 1.2s 0s infinite ease-in-out;
    animation: bot_loader 1.2s 0s infinite ease-in-out;
}

.bot_loader>div {
    background-color: var(--primary);
    width: 4px;
    height: 4px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
}

.drag_preview {
    width: 200px;
    height: 40px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    color: white;
    padding: 10px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
