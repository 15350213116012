.filter-options {
    margin: 8px 0;
    padding: 0 10px 8px;
    border-bottom: 1px solid #eaeaea;
}

.filter-header {
    font-size: 13px;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;
    padding-left: 4px;
}

.filter-buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.filter-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    background: none;
    border: none;
    border-radius: 4px;
    text-align: left;
    font-size: 13px;
    color: #444;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
}

.filter-btn-active {
    background-color: #ebf5ff;
    color: #2563eb;
}

.checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.checkbox-active {
    background-color: #2563eb;
    border-color: #2563eb;
}