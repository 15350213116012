/* Styles for the Knowledge Base Detail component */

/* Center the dialog in the container, excluding the sidebar */
:global(.centered-dialog) {
  /* Adjust the left position to account for the sidebar width (240px) */
  left: calc(50% + 120px) !important; /* 120px is half the sidebar width */
  transform: translate(-50%, -50%) !important;
}

/* Add a media query for smaller screens */
@media (max-width: 768px) {
  :global(.centered-dialog) {
    /* Reset the left position on smaller screens */
    left: 50% !important;
  }
} 