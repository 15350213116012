html {
  ::selection {
    background-color: #b3d4fc !important;
    /* Light blue background for light mode */
    color: black !important;
    /* Black text for light mode */
  }

  /* style for EmojiPicker start */
  .EmojiPickerReact {
    --epr-bg-color: var(--background-default);
    --epr-hover-bg-color: var(--button-hovered-color);
    --epr-focus-bg-color: var(--epr-hover-bg-color);
    --epr-category-label-bg-color: #fdfdfd99;
    /* --background-default (+) opacity: 0.6 */

    --epr-dark: var(--search-input-background);
    --epr-search-input-bg-color: var(--epr-dark);
    --epr-text-color: var(--input-placeholder-color);
  }

  .EmojiPickerReact .epr-search-container input.epr-search {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  /* style for EmojiPicker end */

  --primary: #003750;
  --secondary: #667781;
  --background-default: #fafeff;
  --default-background: #fff;
  --secondary-background-color: #e8f9fd;
  --extradata-bg-color: #f1f1f1;
  --extradata-input-color: #dbdbdb;
  --thread-bg-color: #fff;
  --thread-menu-bg-color: #fff;
  --blueticks-panel-background: #ededed;
  --button-background-color: #dfdfdf;
  --button-hovered-color: #eee;
  --text-color: #7a7a7a;
  --hovered-text-color: #7a7a7a;
  --input-color: #ffff;
  --border-color: #c9ccd4;
  --border-color-two: #f2f2f2;
  --task-panel-bg-color: #fff;
  --task-background-color: #fff;
  --unset-task-bg-color: #dfdfdf;
  --unset-task-value-color: #919191;
  --task-hover-color: #f4fcfd;
  --task-cell-hover-color: #e9f5f7;
  --box-shadow-color: #131c21;
  --shadow-default-color: rgb(0 55 80 / 16%);
  --task-value-hover-color: #53bdeb;
  --overlay-actions-bg-color: #e0e0e0;
  --light-to-dark-color: #ffff;
  --pricing-button-color: #53bdea;
  /*add task input */
  --input-focus-color: #53bdeb;
  --lighter-dark-color: #fff;
  --gray-to-lighter-dark: #d3d3d3;
  --upgrade-color: #003750;
  --extradata-background-color:#fffbf8;
  /*complete details bar*/
  --login-button-bg-color: #ffffff;
  /*boards-panel*/
  --aside-navigation-color: #bbbbbb;
  --toggeable-navigation-color: #eee;
  --toggle-button-bg-color: #fff;
  --toggle-button-otline-color: transparent;
  --active-format-color: #e2e6ea;
  --board-list-item-bg-color: #bac3f838;
  --board-list-item-active-bg-color: #e0e0e0;
  --board-list-item-active-color: #fff;
  /*overlay actions*/
  --clockBD-color: #646464;
  --arrow-bg-color: #f7f7f7;

  --col-item-hover-bg: #ddd;
  --col-item-bd-color: #c7c6c6;
  --panel-header-background: #ffffff;

  --snippets-background-color: #f0f0f0;
  --snippets-search-background-color: #69696926;
  --snippets-add-btn: #53bdeb;

  --snippet-item-background-color: dimgray;
  --snippet-item-color: white;
  --snippet-item-btn-color: #111b21;

  --snippet-btn-color: #53bdeb;
  --snippet-btn-background-color: #f2f4ff;
  --snippet-field-bg-color: #e2e2e3;
  --snippet-btn-bg-secondary: #f2f4ff;
  --snippet-orange-btn: #eb5757;
  --snippet-start-icon-color: #53bdeb;
  --snippet-send-msg-color: #53bdeb;
  --snippet-btn-selection-color: #303030;
  --snippet-btn-selection-bg: #f8f8fa;
  --snippet-lean-icon: #e2e2e3;
  --snippet-new-color: white;
  --snippet-label-color: #d1d7db;

  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  --bt-button-text-disabled: #ffffff;

  --recurrence-repeat-on-color: #80868b;
  --recurrence-repeat-on-bg-color: #f1f3f4;
  --recurrence-repeat-on-selected-color: white;
  --recurrence-repeat-on-selected-bg-color: #53bdeb;

  --calendar-bg: #fff;
  --calendar-title-color: white;
  --calendar-event-color: #3c4043;
  --calendar-header: #70757a;
  --calendar-event-hover-bg: #f1f3f4;
  --calendar-custom-event-color: #fff;
  --calendar-today-bg: initial;
  --calendar-today-label-bg: #53bdeb;
  --calendar-today-label-color: white;
  --calendar-timeslot-group-border-color: rgb(221 221 221 / 50%);
  --calendar-toolbar-btn-border-color: var(--primary);
  --calendar-toolbar-text-color: var(--primary);
  --calendar-toolbar-hover-bg: #f1f3f4;
  --calendar-toolbar-hover-color: var(--primary);

  --bt-button-text-disabled: var(--secondary);
  --very-light-gray: #e3e3e3;
  --link-preview-background: rgba(255, 255, 255, 0.5);

  /*uriel added colors*/
  --secondary-text-color: #013750;
  --field-hover-border-color: #000000;
  --field-background-color: #ffffff;
  --field-border-color: #5f5f5f;
  --field-focused: #096101;
  --toggle-chevron: #000000;
  --icon: rgb(230, 166, 52);
  --placeholder-text: #858585;
  --billing-blue-title: #3babeb;
  --billing-btn-bg: #e8f8fd;
  --link-text-color: #53bdeb;
  --task-edit-mode: #c4f5ff;
  --button-hover: #ffffff;
  --x-hover: #ddd;
  --empty-picker-cell: #c4c4c4f5;
  --sub-tasks-bg-color: #fafbff;
  --drag-handle: #000;
  --kanban-task-shadow: #a3a3a3;
  --option-picker-button: #063448;
  --google-sign-in: #fdfdfd;
  --upgrade-background-color: #ffffff;
  --drag-over-bgcolor: #d9f3fa;
  --calendar-event-shadow:rgba(0, 0, 0, 0.4);

  /* boards-panel */
  --boards-panel-background: white;
  --separator-line: #ebeff8;
  --tasks-header-background: #fafeff;
  --search-input-background: rgb(240, 242, 245);
  --search-input-border: #e5e5e5;
  --search-input-border-hover: #d5d5d5;
  --input-placeholder-color: #a5a5a5;
  --scrollbar-color: #c5e9f1;
  --toggle_drawer_icon-background: #e8f9fd;
  --board-item-menu-hover: white;

  --cell-name-color: #869ca8;
  --new-item-color: #869ca8;
  --collapse_subtasks_button-background: #ddf9ff;
  --upgrade-box-plan-background: #d9f3fa;

  /*labesls-feature */
  --label-bg-color: #ffffff;
  --label-input-wrapper-bd-color: #e0e0e0;
  --label-border-color: #eeeeee;
  --label-shadow-color: #cccccc;
  --label-text-color: #424242;
  --label-button-color: #808080;
  --label-button-bg-color: #eeeeee;
  --label-button-icon-bg-color: transparent;
  --label-button-hover-color: #f3f3f3;

  /* extension*/
  --schedule-message-editor-input-bg: #ebebeb;
  --campaign-message-editor-input-bg: #e1f4df;
  --schedule-message-editor-link-preview-bg: #acacac;
  --campaign-message-editor-link-preview-bg: #D6E8D4;
  --campaign-message-toggle-track: #42d871;
  --schedule-message-toggle-track: #95dfff;
  --campaign-message-toggle-switch: #42d871;
  --schedule-message-toggle-switch: #53bdeb;
  --schedule-message-link-preview-input-border: #53bdeb;
  --campaign-message-link-preview-input-border: #42d871;
  --top-header-fab-hover-bg: #ffffff;
  --manage-extension-menu-headers-bg: rgba(240, 242, 245, 1);
  --campaign-tabs-string: rgb(0, 50, 15);
  --tudo-import-ilustration: #d9f8ff;
  --campaign-import-ilustration: #d9f8ff;
  --import-bg: #ffffff;
  --campaign-finish-view-audience-label-bg: #f2f2f2;
  --campaign-audience-label-border: rgba(0, 0, 0, 0.5);
  --scheduled-message-status-tag-bg: #ebebeb;
  --campaign-border: rgba(0, 0, 0, 0.05);
  --import-border: rgba(0, 0, 0, 0.05);
  --gradient-background-edge: (255, 255, 255, 0.5);
  --gradient-background-center: (255, 255, 255, 1);
  --campaign-time-and-date-disabled: #afbeb3;
  --campaign-template-modal-bg:rgb(255, 255, 255);
  --extension-top-header-border: #dbdcdf;
  --campaign-finish-tab-blur-button-border: rgba(0, 0, 0, 0.5);
  --campaign-finish-tab-blur-button-bg: rgba(255, 255, 255, 0.75);
  --campaign-finish-tab-blur-button-bg-hover: rgba(255, 255, 255);
  --campaign-dialog-row-hover: #ecfceb;
  --campaign-template-dropdown-button-bg: #004e17;
  --campaign-template-dropdown-button-hover: #005c1c;
  --contact-table-row-hover:#f5f5f5;
  --qr-modal-upgrade-bg: #ededed;
  --template-label-bg: #e1f4df;
  --template-label-text: #38bf62;
  --bulk-item-row-menu-border: #fff;
  --campaign-dialog-green-text: rgba(53, 190, 95, 0.75);
  --top-header-border-bottom: #dbdcdf;
  --campaign-status-completed-bg: #35BE5F;
  --campaign-status-completed-text: #E1F4DF;
  --campaign-status-pending-bg: #d9d9d9;
  --campaign-status-pending-text: #000;
  --campaign-status-running-bg:#E1F4DF;
  --campaign-status-running-text: #37BE96;
  --campaign-status-aborted-bg:#30202A;
  --campaign-status-aborted-text: #E2435C;
  --campaign-status-paused-bg: #d9d9d9;
  --campaign-status-paused-text: #000;
  --campaign-finish-tab-title-bg:#E1F4DF;
  --campaign-finish-tab-title-text: #35BE5F;
  --disabled-import-button-bg: #ffc1ae;
  --contact-avatar-bg: #dfe5e7;
  --contact-avatar-fill: #FFFFFF;
  --bulk-button-bg: #e1f4df;
  --bulk-button-bg-hover: #ccf9c7;
  --bulk-button-text: #35BE5F;
  --wa-message-timestamp: #53bdeb;
  --wa-message-bg: #ffffffab;
}

.dark {
  ::selection {
    background-color: #8E9197 !important;
    /* Darker background for dark mode */
    color: white !important;
    /* White text for dark mode */
  }



  /* style for EmojiPicker start */
  .EmojiPickerReact.epr-dark-theme {
    --epr-bg-color: var(--background-default);
    --epr-hover-bg-color: var(--button-hovered-color);
    --epr-focus-bg-color: var(--epr-hover-bg-color);
    --epr-category-label-bg-color: #00233499;
    /* --background-default (+) opacity: 0.6 */

    --epr-dark: var(--search-input-background);
    --epr-search-input-bg-color: var(--epr-dark);
    --epr-text-color: var(--input-placeholder-color);
  }
  /* style for EmojiPicker end */


  --primary: #ffffff;
  --secondary: #8696a0;
  --background-default: #002334;
  --default-background: #002334;
  --shadow-default-color: rgb(0 0 0/ 50%);
  --secondary-background-color: #013750;
  --blueticks-panel-background: #2a2f32;
  --button-background-color: #002334;
  --button-hovered-color: #003e5a;
  --text-color: #cfcfcf;
  --extradata-background-color:#003e5a;

  --extradata-bg-color: #323739;
  --extradata-input-color: #3e4345;
  --thread-bg-color: #002334;
  --thread-menu-bg-color: #002334;
  --text-color: #dfdfdf;
  --hovered-text-color: #7a7a7a;
  --input-color: #131c21;
  --border-color: #013750;
  --border-color-two: #101313;
  --task-panel-bg-color: #111;
  --task-background-color: #002334;
  --unset-task-bg-color: #4e565b;
  --unset-task-value-color: #fff;
  --task-hover-color: #003e5a;
  --task-cell-hover-color: #00405e;
  --box-shadow-color: #0e0b0b;
  --task-value-hover-color: #fff;
  --overlay-actions-bg-color: #4e565b;
  --light-to-dark-color: #323739;

  /*add task input */
  --input-focus-color: #53bdeb;
  --lighter-dark-color: #383d3e;
  --gray-to-lighter-dark: #A7B1B7;
  --panel-header-background: #202c33;

  /*complete details bar*/
  --login-button-bg-color: #131c21;
  /*boards-panel*/
  --board-list-item-bg-color: #bac3f817;
  --board-list-item-active-bg-color: #003e5a;
  --board-list-item-active-color: #fff;
  --aside-navigation-color: #131c21;
  --toggeable-navigation-color: #323739;
  --toggle-button-bg-color: #323739;
  --toggle-button-otline-color: #8999f54b;
  --active-format-color: #42484a;
  /*overlay actions*/
  --clockBD-color: #cfcfcf;
  --arrow-bg-color: #2e3436;

  --col-item-hover-bg: #585e61;
  --col-item-bd-color: #c7c6c6;

  --snippets-background-color: #2c2e2f;
  --snippet-titles-color: white;
  --snippet-field-bg-color: #f8f8fa;
  --snippet-input-color: #d1d7db;

  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

  --bt-button-text-disabled: #8da2ad;

  --recurrence-repeat-on-bg-color: var(--background-default);

  --calendar-bg: var(--background-default);
  --calendar-event-color: white;
  --calendar-event-hover-bg: #00000070;
  --calendar-event-hover-color: white;
  --calendar-timeslot-group-border-color: rgb(221 221 221 / 10%);
  --calendar-toolbar-btn-border-color: var(--primary);
  --calendar-toolbar-hover-bg: black;
  --calendar-toolbar-text-color: white;

  --very-light-gray: #ebebeb;
  --link-preview-background: rgba(0, 0, 0, 0.5);

  /*uriel added colors*/
  --secondary-text-color: #819ba8;
  --field-hover-border-color: #ee0909;
  --field-background-color: #003e5a;
  --field-border-color: #a0a0a0;
  --field-focused: #3bf7ed;
  --toggle-chevron: #ffffff;
  --icon: rgb(230, 166, 52);
  --placeholder-text: #888888;
  --billing-blue-title: #b1e9fa;
  --billing-btn-bg: #3babeb;
  --link-text-color: #fff;
  --task-edit-mode: #024d70;
  --button-hover: #003e5a;
  --x-hover: #005e8a;
  --empty-picker-cell: #869ca8;
  --sub-tasks-bg-color: #254a72;
  --drag-handle: #fff;
  --kanban-task-shadow: #3a3a3a;
  --option-picker-button: #297392;
  --google-sign-in: #202124;
  --upgrade-background-color: #29367c;
  --drag-over-bgcolor: #00344e;
  --calendar-event-shadow:rgba(255, 255, 255, 0.4);

  /* boards-panel */
  --boards-panel-background: var(--background-default);
  --separator-line: #003e5a;
  --tasks-header-background: #00283c;
  --search-input-background: #003e5a;
  --search-input-border: transparent;
  --search-input-border-hover: transparent;
  --input-placeholder-color: #809ba7;
  --scrollbar-color: #003e5a;
  --toggle_drawer_icon-background: #003750;
  --board-item-menu-hover: var(--background-default);

  --cell-name-color: #869ca8;
  --new-item-color: #869ca8;
  --collapse_subtasks_button-background: #013750;
  --upgrade-color: #ffa121;

  /*labesls-feature */
  --label-top-header-bg:#202c33;
  --label-bg-color: #202c33;
  --label-shadow-color: #000000;
  --label-input-wrapper-bd-color: #212121;
  --label-border-color: #424242;
  --label-text-color: #dfdfdf;
  --label-button-color: #dfdfdf;
  --label-button-bg-color: #424242;
  --label-button-icon-bg-color:transparent;
  --label-button-hover-color: #7e7e7e;

  /* extension*/
  --schedule-message-editor-input-bg: #505050;
  --campaign-message-editor-input-bg: #1c2b1b;
  --schedule-message-editor-link-preview-bg: #3a3a3a;
  --campaign-message-editor-link-preview-bg: #253324;
  --campaign-message-toggle-track: #42d871;
  --schedule-message-toggle-track: #939393;
  --campaign-message-toggle-switch: #42d871;
  --schedule-message-toggle-switch: #d1d1d1;
  --schedule-message-link-preview-input-border: #d1d1d1;
  --campaign-message-link-preview-input-border: #42d871;
  --top-header-fab-hover-bg: #000000;
  --manage-extension-menu-headers-bg: #00111a;
  --campaign-tabs-string: #60b558;
  --tudo-import-ilustration: #003a47;
  --campaign-import-ilustration: #003a47;
  --import-bg: #262626;
  --import-border: #1a1a1a;
  --campaign-finish-view-audience-label-bg: #252525;
  --campaign-audience-label-border: #d1d1d1;
  --scheduled-message-status-tag-bg: #202c33;
  --campaign-border: rgba(255, 255, 255, 0.05);
  --gradient-background-edge: (0, 35, 52, 0.5);
  --gradient-background-center: (0, 35, 52, 1);
  --campaign-time-and-date-disabled: #646b6f;
  --campaign-template-modal-bg:#00111a;
  --extension-top-header-border: #313d45;
  --campaign-finish-tab-blur-button-border: rgba(255, 255, 255, 0.5);
  --campaign-finish-tab-blur-button-bg: rgba(45, 45, 45, 0.75);
  --campaign-finish-tab-blur-button-bg-hover: rgba(0, 0, 0);
  --campaign-dialog-row-hover: #1d3c24;
  --campaign-template-dropdown-button-bg: #004e17;
  --campaign-template-dropdown-button-hover: #005c1c;
  --contact-table-row-hover: rgba(255, 255, 255, 0.08);
  --qr-modal-upgrade-bg: #4d4d4d;
  --template-label-bg: #1c2b1b;
  --template-label-text: #60b558;
  --bulk-button-bg: #1c2b1b;
  --bulk-button-bg-hover: #063009;
  --bulk-button-text: #60b558;
  --bulk-item-row-menu-border: #535757;
  --campaign-dialog-green-text: #1b761d;
  --top-header-border-bottom: #88898b;
  --campaign-status-completed-bg: #17761D;
  --campaign-status-completed-text:#E0EDA6;
  --campaign-status-pending-bg:#1D272C;
  --campaign-status-pending-text:#C6C9CA;
  --campaign-status-running-bg:#1C2B1B;
  --campaign-status-running-text:#50B558;
  --campaign-status-aborted-bg:#31222B;
  --campaign-status-aborted-text:#EB4C63;
  --campaign-status-paused-bg:#1D272C;
  --campaign-status-paused-text:#C6C9CA;
  --campaign-finish-tab-title-bg:#1C2B1B;
  --campaign-finish-tab-title-text: #17761D;
  --disabled-import-button: #613123;
  --contact-avatar-bg: #6A7175;
  --contact-avatar-fill: #CFD4D6;
  --bulk-button-bg-hover: #063009;
  --bulk-button-text: #17761D;
  --wa-message-timestamp: #4DBDEB;
  --wa-message-bg: #343332;
}



::placeholder {
  color: var(--placeholder-text);
  opacity: 1;
}