.trigger_container {
    position: relative;
    border-radius: 75px;
    width: 200px;
    height: 123px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 10px;
    text-align: center;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.trigger_title {
    font-weight: 600;
    font-size: 14px;
    color: #333;
    margin-bottom: 4px;
    white-space: nowrap;
}

.trigger_description {
    font-size: 11px;
    color: #666;
    opacity: 0.8;
    line-height: 1.2;
}

.trigger_source_handle {
    background-color: #b6d7a8;
    border: 2px solid white;
    border-radius: 50%;
    z-index: 3;
    transition: all 0.2s ease;
    
    &:hover {
        border-color: darken(#b6d7a8, 10%) !important;
        background-color: white !important;
    }
}

// Handle hover state for the node
.trigger_container:hover .trigger_source_handle {
    border: 3px solid #b6d7a8 !important;
    background-color: white !important;
}

// Dialog styles
.add_trigger_dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.add_trigger_dialog_body {
    padding: 0 20px;
}

.section_title {
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 12px;
}

.divider {
    margin: 20px 0;
    border-bottom: 1px solid rgb(227, 227, 227);
}
