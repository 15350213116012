
.free_text_checkbox {
    margin: 16px 0;
    
    .option_container {
      background-color: #f8f9fa;
      border-radius: 10px;
      padding: 12px 16px;
      transition: all 0.2s ease;
      
      &:hover {
        background-color: #f2f3f5;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      }
    }
    
    .checkbox_wrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .checkbox_input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      
      &:checked + .checkbox_label .checkbox_custom {
        background-color: #0070f3;
        border-color: #0070f3;
        
        &:after {
          opacity: 1;
          transform: rotate(45deg) scale(1);
        }
      }
      
      &:focus + .checkbox_label .checkbox_custom {
        box-shadow: 0 0 0 3px rgba(0, 112, 243, 0.2);
      }
    }
    
    .checkbox_label {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      width: 100%;
    }
    
    .checkbox_custom {
      position: relative;
      width: 20px;
      height: 20px;
      border: 2px solid #cbd5e0;
      border-radius: 4px;
      margin-right: 12px;
      transition: all 0.2s ease;
      flex-shrink: 0;
      
      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        opacity: 0;
        transform: rotate(45deg) scale(0.5);
        transition: all 0.2s ease;
      }
    }
    
    .checkbox_text {
      display: flex;
      flex-direction: column;
    }
    
    .feature_title {
      font-weight: 600;
      font-size: 14px;
      color: #2d3748;
    }
    
    .feature_description {
      font-size: 12px;
      color: #718096;
      margin-top: 2px;
    }
  }