.members {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-inline-end: 10px;
    width: 25%;
}

.member {
    flex-basis: 32px;
    border-radius: 50%;
}

// Avatar group styling
.avatarGroup {
    :global(.MuiAvatar-root) {
        width: 28px;
        height: 28px;
        font-size: 0.875rem;
        border: 2px solid var(--default-background);
        transition: transform 0.2s ease;
        
        &:hover {
            transform: scale(1.1) translateY(-2px);
            z-index: 10;
        }
    }
}

// Menu button styling
.menuButton {
    margin-left: 0.75rem;
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    transition: all 0.15s ease;
    
    &:hover {
        background: rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
    
    &:active {
        background: rgba(0, 0, 0, 0.1);
        box-shadow: none;
    }
}

// Menu header styling
.menuHeader {
    padding: 10px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    background-color: rgba(0, 0, 0, 0.01);
    
    h4 {
        margin: 0;
        font-size: 0.8rem;
        font-weight: 600;
        color: var(--text-secondary);
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
}

// Menu item styling
.menuItem {
    padding: 7px 12px;
    transition: all 0.15s ease;
    
    &:hover {
        background-color: rgba(25, 118, 210, 0.04);
        border-left: 3px solid var(--primary-color);
    }
    
    &:active {
        background-color: rgba(25, 118, 210, 0.08);
    }
}

.menuItemContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 12px;
}

.menuItemIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: rgba(25, 118, 210, 0.08);
}

.menuItemText {
    display: flex;
    flex-direction: column;
    flex: 1;
    
    p:first-child {
        margin: 0;
        font-weight: 500;
        font-size: 0.95rem;
        color: var(--text-primary);
    }
    
    p:last-child {
        margin: 3px 0 0 0;
        font-size: 0.75rem;
        color: var(--text-secondary);
    }
}

// Modal styling
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-height: 90vh;
    background-color: var(--default-background);
    border-radius: 16px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.18);
    overflow: hidden;
    animation: fadeIn 0.25s ease-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -45%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.modalHeader {
    display: flex;
    flex-direction: row;
    padding: 0 24px;
    padding-block: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    align-items: center;
    justify-content: space-between;
    
    h2 {
        margin: 0;
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--text-primary);
        letter-spacing: -0.01em;
    }
}

.modalContent {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: auto;
}

.sectionHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    
    h3 {
        margin: 0;
        font-size: 1rem;
        font-weight: 600;
        color: var(--text-primary);
    }
    
    span {
        font-size: 0.85rem;
        color: var(--text-secondary);
        font-weight: 500;
    }
}

.userListContainer {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 100%;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.02);
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;
    
    p:first-child {
        margin: 4px 0;
        color: var(--text-primary);
        font-weight: 500;
        font-size: 0.95rem;
    }
    
    p:last-child {
        margin: 4px 0;
        font-size: 0.85rem;
        color: var(--text-secondary);
        text-align: center;
    }
}

.loadingState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-block: 4rem;
    
    p:first-child {
        color: var(--text-primary);
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
    }
    
    p:last-child {
        color: var(--text-secondary);
        font-size: 0.85rem;
        margin: 8px 0 0 0;
    }
}

.search_input {
    width: 100%;
    margin: 0 0 8px 0;
    padding: 0px 4px;
    background: var(--search-input-background) !important;
}

@media (min-width: 1024px) {
    .search_input {
        font-weight: 500;
        border-radius: 8px;
        font-size: 14px;
        border: 0;
        height: 36px;
        padding: 0 16px;
        margin: 0 10px;
        outline: none;
        width: 267px;
        max-width: 100%;
    }
}

.search_input:focus {
    box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}

.closeButton {
    color: var(--text-secondary);
    width: 36px;
    height: 36px;
    transition: all 0.2s ease;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
        color: var(--text-primary);
        transform: rotate(90deg);
    }
}