.headerContainer {
  text-align: center;
}

.headerLogoImage {
  max-width: 200px;
  height: auto;
}

.titleContainer {
  text-align: center;
}

.mainTitle {
  font-size: 24px;
  margin: 0;
}

.contentContainer {
  max-width: 500px;
  margin: 0 auto;
}

.statusContainer {
  margin-top: 24px;
}

.error {
  color: #f44336;
} 