.condition_node_wrapper {
    min-height: 100px;
    padding: 4px;
    border-radius: 5px;
}

.add_condition_popover_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin: 0px -20px;
}

.add_condition_popover_body {
    border-top: 1px solid rgb(227, 227, 227);
    padding-top: 20px;
}

.condition_popover_body_header {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 12px;
}

.condition_popover_box {
    display: grid;
    gap: 20px;
    margin-bottom: 10px;
    background: rgb(245, 246, 250);
    border-radius: 5px;
    padding: 10px;
}

.y_handle {
    background-color: green !important;
    position: absolute;
    top: 35% !important;
    z-index: 3;
}

.n_handle {
    background-color: red !important;
    position: absolute;
    margin-top: 5% !important;
    z-index: 3;
}

.condition_node_wrapper:hover~.y_handle,
.y_handle:hover {
    border: 3px solid green !important;
    background-color: white !important;
}

.condition_node_wrapper:hover~.n_handle,
.n_handle:hover {
    border: 3px solid red !important;
    background-color: white !important;
}