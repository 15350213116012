.llm_dialog_header {
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #0A3750;
}

.llm_dialog_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.llm_dialog_content {
    padding-top: 26px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.llm_dialog_title {
    font-size: 14px;
    font-weight: 500;
    color: #0A3750;
    margin-bottom: 10px;
}

.divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 20px 0;
    width: 100%;
}

.settings_section {
    display: flex;
    flex-direction: column;
}

.checkbox_container {
    display: flex;
    align-items: center;
    // gap: 10px;
}

.checkbox {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.checkbox_label {
    font-size: 14px;
    color: #0A3750;
    cursor: pointer;
}

.variable_section {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.variable_label {
    font-size: 14px;
    font-weight: 500;
    color: #0A3750;
}

.variable_hint {
    font-size: 12px;
    color: #676767;
    margin-top: 4px;
}

.button_container {
    margin-top: auto;
    padding-top: 30px;
}

.llm_footer {
    padding: 12px 16px;
    border-top: 1px solid rgb(232, 234, 242);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
    transition: background-color 0.3s ease;
}

.variable_info {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--text-secondary);
    font-weight: 500;
    letter-spacing: 0.2px;

    .variable_icon {
        font-size: 18px;
        margin-right: 8px;
        opacity: 0.9;
    }

    code.variable_code {
        padding: 4px 10px;
        border-radius: 4px;
        font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
        font-weight: 600;
        margin-left: 8px;
        border-width: 1px;
        border-style: solid;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }
    }
}

.set_variable_prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    border-radius: 6px;
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    border: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.2, 0, 0, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

    .settings_icon {
        font-size: 18px;
        margin-right: 8px;
    }

    &:hover {
        background: linear-gradient(135deg, #f1f3f5, #e9ecef);
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
        border-color: rgba(0, 0, 0, 0.15);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
}