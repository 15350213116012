.rbc-calendar,
.rbc-off-range-bg {
  background: var(--calendar-bg);
}

.rbc-row-bg {
  z-index: 4;
}


.rbc-addons-dnd-row-body {
  position: relative;
  pointer-events: auto;
}

.rbc-date-cell {
  height: 25px;
}

.rbc-event {
  pointer-events: all;
}

.rbc-event,
.rbc-event.rbc-selected {
  background-color: inherit;
  color: var(--calendar-event-color);
  height: 40px !important;
  z-index: 9 !important;
  position: relative;
  padding: 0px 2px;
}

.rbc-event-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 0;
}

.rbc-day-slot.rbc-time-column div .rbc-events-container {
  margin: 0px 3px 0px 3px;
}

.rbc-row-segment {
  height: 40px;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none;
}

.rbc-event-label {
  display: none;
}

.rbc-day-slot .rbc-event {
  border: unset;
}

.rbc-event>.rbc-event-content {
  width: 100%;
}

.rbc-time-content {
  border-top: 0px !important;
}

.rbc-month-row {
  border-top: 0px !important;
}

.rbc-month-view {
  border: 0px !important;
}

.rbc-header+.rbc-header {
  border-left: 0px !important;
}

.cal-toolbar-today {
  margin: 7px 24px 0px 7px;
}

.rbc-row.rbc-time-header-cell>.rbc-header {
  border-top: 0px !important
}

.rbc-time-content>*+*>* {
  border-left: 0px !important;
}

.rbc-toolbar .rbc-toolbar-label,
.rbc-toolbar .rbc-btn-group button,
.rbc-header {
  font-family: "Google Sans", Roboto, Arial, sans-serif;
}

.rbc-header {
  text-transform: uppercase;
  color: var(--calendar-header);
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  border-top: 1px solid var(--calendar-timeslot-group-border-color) !important;
}

.rbc-today {
  background-color: var(--calendar-today-bg);
}

.rbc-now.rbc-current .rbc-button-link {
  background-color: var(--calendar-today-label-bg);
  color: var(--calendar-today-label-color);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.rbc-day-slot .rbc-time-slot {
  border-top: unset;
}

.rbc-timeslot-group,
.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td,
.rbc-header,
.rbc-agenda-table>thead>tr>.rbc-header {
  border-bottom: 1px solid var(--calendar-timeslot-group-border-color) !important;
}

.rbc-row.rbc-month-header> :nth-child(7),
.rbc-agenda-view table.rbc-agenda-table thead>tr>th,
.rbc-time-header.rbc-overflowing {
  border-right: 1px solid var(--calendar-timeslot-group-border-color) !important;
}

.rbc-day-slot.rbc-time-column,
.rbc-row.rbc-month-header> :nth-child(1),
.rbc-month-row>.rbc-row-bg> :nth-child(1),
.rbc-time-header-content,
.rbc-day-bg+.rbc-day-bg,
.rbc-agenda-view table.rbc-agenda-table thead>tr>th,
.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left: 1px solid var(--calendar-timeslot-group-border-color) !important;
}

.rbc-agenda-time-cell,
.rbc-time-view,
.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid var(--calendar-timeslot-group-border-color) !important;
}

.rbc-header.rbc-today>button>span {
  font-weight: bold;
}


.rbc-timeslot-group>* {
  height: 37px !important;
  box-sizing: border-box !important;
}

.rbc-time-gutter.rbc-time-column {
  border-top: 1px solid var(--calendar-timeslot-group-border-color) !important;
}

.rbc-label.rbc-time-header-gutter,
.rbc-time-gutter.rbc-time-column {
  width: 85px !important;
}

.rbc-time-gutter.rbc-time-column>.rbc-timeslot-group>:nth-child(2) {
  visibility: hidden !important;
}

.rbc-day-slot.rbc-time-column>.rbc-timeslot-group>div {
  opacity: 0 !important;
  transition: opacity 0.2s;
}

.rbc-day-slot.rbc-time-column>.rbc-timeslot-group>div:hover {
  opacity: 1 !important;
}

.rbc-time-header.rbc-overflowingt {
  margin-right: 16px !important;
}

.rbc-date-cell>div>:nth-child(2) {
  opacity: 0;
}

.rbc-date-cell:hover>div> :nth-child(2) {
  opacity: 1 !important;
}

.rbc-row-segment:has(.rbc-button-link.rbc-show-more) {
  height: 22px !important;
}



.rbc-row-content, .rbc-addons-dnd-row-body, .rbc-row, .rbc-row-segment {
  pointer-events: none!important;
}


/* .rbc-row-bg>div:hover{
  .rbc-date-cell>div>:nth-child(2) {
    opacity: 1 !important;
  }
} */


/* .rbc-row-content,
.rbc-row-content .rbc-addons-dnd-row-body,
.rbc-row-content .rbc-row,
.rbc-row-content .rbc-row-segment,
.rbc-row-content .rbc-event,
.rbc-row-content .rbc-row-contentrbc-event-content {
  pointer-events: none;
} */

/* Fix for DnD issues */
.rbc-addons-dnd {
  .rbc-addons-dnd-row-body {
    position: relative;
    pointer-events: auto;
  }

  /* Consistent drag preview styling across all views */
  .rbc-addons-dnd-drag-preview {
    position: absolute !important;
    z-index: 1000;
    pointer-events: none;
    width: 180px !important;
    height: 40px !important;
    transition: none !important;
    background-color: inherit;
    opacity: 0.8;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

    /* Force consistent styling */
    .rbc-event-content {
      width: 100% !important;
      height: 100% !important;
      padding: 2px 4px !important;
    }
  }

  .rbc-event {
    transition: opacity 0.3s;
    cursor: move;

    &.rbc-addons-dnd-dragged-event {
      opacity: 0.5;
    }
  }

  /* View specific adjustments */
  .rbc-time-view & {
    .rbc-addons-dnd-drag-preview {
      position: absolute !important;
      left: auto !important;
      margin: 0 !important;
    }
  }

  /* Prevent scroll jumps in time views */
  .rbc-time-view {
    overflow: hidden !important;

    .rbc-time-content {
      overflow-y: scroll !important;
      overflow-x: hidden !important;

      &::-webkit-scrollbar {
        width: 8px;
      }
    }
  }
}

/* Prevent pointer event issues in time views */
.rbc-time-view {
  .rbc-events-container {
    pointer-events: none;
  }

  .rbc-event {
    pointer-events: all !important;
  }

  .rbc-time-slot {
    pointer-events: all !important;
  }
}

.rbc-events-container {
  pointer-events: none;

  .rbc-event {
    pointer-events: auto;
  }
}