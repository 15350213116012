.contactListContainer {
    // position: absolute; // DONT DELETE - MAYBE EXTENTION NEED IT - ASK NOAM
    z-index: 10000;
    width: 100%;
    // height: calc(100% - 108px); // DONT DELETE - MAYBE EXTENTION NEED IT - ASK NOAM
    background-color: var(--search-container-background);
    overflow: auto;
}

.header {
    background-color: var(--search-container-background);
    border-bottom: 1px solid var(--border-stronger);
    border-top: 1px solid var(--border-stronger);
    display: flex;
    justify-content: flex-end;
    //padding: 0 5px;
    height: 47px;
    align-items: center;
    padding-left: 12px;
    color: var(--primary);
}

.messagePreviewContainer {
    display: flex;
    flex-direction: column;
    color: var(--secondary);
    font-size: 13px;
    justify-content: flex-end;
}

.contactContainer {
    display: flex;
    height: 72px;
    color: var(--primary-strong);
    font-size: 16px;

    &.closed {
        display: none;
    }

    .contactImageWrapper {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid var(--border-list);

        .contactImageContainer {
            width: 49px;
            height: 49px;
            border-radius: 50%;
            overflow: hidden;

            img,
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .contactInfoWrapper {
        display: flex;
        padding-left: 15px;
        border-bottom: 1px solid var(--border-list);
        flex: 1;
        justify-content: space-between;
        align-items: center;
        min-width: 0;

        .contactName {
            font-size: 16px;
            font-weight: 500;
        }

        .messagePreview {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            margin-top: 8px;
            color: var(--secondary);
        }
    }
}

html[dir=ltr] {
    .contactInfoWrapper {
        padding-left: 0;
        padding-right: 15px;
    }
}