.event,
.monthEvent {
    &:hover {
        background-color: var(--calendar-event-hover-bg);
        color: var(--calendar-event-hover-color);
        -webkit-border-radius: 4px;
        border-radius: 4px;
    }
}

.wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 5;
    position: relative;
    background-color: var(--default-background);
}

.monthEvent {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    border-radius: 5px;
    box-shadow: var(--calendar-event-shadow) 0px 1px 4px;
    height: 32px;
    width: 99%;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;

    .trashIcon {
        position: absolute;
        right: 8px;
        opacity: 0;
        transition: opacity 0.2s ease;
        color: var(--destructive);
        cursor: pointer;
        z-index: 10;
        
        &:hover {
            transform: scale(1.1);
        }
    }

    &:hover {
        background-color: var(--background-default-hover);

        .trashIcon {
            opacity: 1;
        }
    }

    &.highlighted {
        background: linear-gradient(
            90deg,
            rgba(0, 132, 255, 0) 25%,
            rgba(0, 132, 255, 0.25) 37%,
            rgba(0, 132, 255, 0) 63%
        );
        background-size: 200% 100%;
        animation: shimmer 1s infinite;
    }

    .circle {
        -webkit-border-radius: 8px;
        border-radius: 8px;
        border: 4px solid;
        height: 0;
        width: 0;
    }

    .time {
        margin: 0px 4px 0px 4px;
        color: var(--primary-lighter);
        font-size: 12px;
    }

    .contacts {
        margin: 0 4px;
        font-style: italic;
    }
}


.customEvent {
    width: 100%;
    color: var(--calendar-custom-event-color);
    display: flex;
    justify-content: flex-start;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 4px;
    letter-spacing: .1px;
    line-height: 15px;
    transition: width 500ms;
    border: 2px solid white;

    .time {
        margin: 0px 4px 0px 0px;
    }
}

.customEventWrapper {
    width: 100%;
    position: relative;
}


.customEventAddButton {
    color: #626262;
    background-color: #e4e4e4;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 76%);
    transition: background-color 0.3s;
    width: 12px;
    height: 12px;
    z-index: 9;
    opacity: 0;
    position: absolute !important;
    right: 0;
}

.customEventWrapper:hover .customEvent {
    width: calc(100% - 20px);
}

.customEventWrapper:hover .customEventAddButton {
    opacity: 1;
    transition: opacity 0.3s ease-out 0.5s;
    /* Apply delay only when hovering */
}

/* No delay when mouse leaves */
.customEventAddButton {
    transition: opacity 0.3s ease-out, background-color 0.3s;
}


.customDayCell {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* Adjust this if you need different spacing */
    width: 100%;
    height: 100%;
    z-index: 3;
}

.dayLabel {
    margin-right: auto;
    /* Pushes everything else to the right */
    padding-left: 4px;
    /* Adds some padding to the left side */
}


.monthDayBackground {
    height: 100%;
    width: 100%;
    position: relative;
    /* Allows absolute positioning within */
    border-bottom: 1px solid var(--calendar-timeslot-group-border-color);
    border-right: 1px solid var(--calendar-timeslot-group-border-color);
    // border-left: 1px solid #dddddd; // from staging, before merg into calendar2
    z-index: 4;
    // pointer-events: none;
}


.dayBackground {}


.addButton {
    margin-left: auto;
    height: 20px;
    width: 20px;
    color: #626262;
    background-color: #e4e4e4;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 76%);
    transition: background-color 0.3;
}

.addDayButton {
    color: #626262;
    background-color: #e4e4e4;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 76%);
    transition: background-color 0.3s;
    width: 12px;
    height: 12px;
    z-index: 9;
    // opacity: 0;
    // transition: opacity 0.3s ease-out,
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}