.source_handle {
    background-color: #FFB906 !important;
    z-index: 3;
}


.send_message_node_wrapper:hover~.source_handle,
.source_handle:hover {
    border: 3px solid #FFB906 !important;
    background-color: white !important;
}

.add_webhook_dialog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.divider {
    width: 500px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(227, 227, 227);
}

.title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 12px;
    flex-wrap: wrap;
    gap: 5px;
}

.secondary_title {
    margin-top: 10px;
    color: rgb(102, 102, 102);
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}

.method_select {
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 28px;
    background-color: white;
    border-radius: 5px;
    height: 30px;
    padding-left: 5px;
    color: #666666;
    font-size: 14px;
}

.response_routes {
    flex-direction: column;
    text-align: left;
}

.response_route {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 5px 25px;
    min-height: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgb(102, 102, 102);
    flex-direction: column;
    word-break: break-word;
    background-color: #F5F6FA;
    border-radius: 5px;
}