.source_handle {
    background-color: #4A90E2;
    z-index: 3;
    right: -2px !important;
    
    &:hover {
        background-color: darken(#4A90E2, 10%);
    }
}

.agent_source_handle {
    right: -8px !important;
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
    background-color: #4A90E2 !important;
    border: none !important;
}

.agentNode {
  z-index: 1;
  min-width: 300px;
}

.toolNodeContainer {
  position: relative;
}

.toolNode {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border: 2px solid white;
}

.toolNode:hover {
  transform: scale(1.1);
}

.toolNodeText {
  color: white;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 40px;
} 