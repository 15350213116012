.pageContainer {
  height: 100vh;
  direction: ltr;
  display: flex;
  background-color: var(--background-default);
}

.titleContainer {
  background-color: var(--background-default);
  border-bottom: 1px solid var(--separator-line);
  padding: 12px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 81px;
  justify-content: space-between;
}

.pageTitle {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primary-text);
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contentContainer {
  flex: 1;
  overflow: auto;
  height: calc(100vh - 81px);
}

.noHeaderContent {
  height: 100vh;
} 